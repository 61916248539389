import './scss/app.sass';

window.$ = require('jquery');
import Navigo from 'navigo';

import main from './main';
import success from './success';
import fail from './fail';
import axios from "axios";

window.addEventListener("load", () => {
    const router = new Navigo("/");
    const pagesDir = '/pages/';

    let render = function (page, script = false, params = {}) {
        fetch(pagesDir + page + '.html')
            .then(response => response.text())
            .then(text => {
                document.getElementById('content').innerHTML = text;
                if (script) {
                    script(params.data);
                }
            });
    };

    router
        .on("/", function () {
            axios.post(process.env.API_URL + 'api/prices/get')
                .then(function (response) {
                    response = response.data;
                    if(response.status){
                        render('main', main, response);
                    }else{
                        console.log('Error getting data from server');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
        .on("/success/:uuid", function (params) {
            render('success', success, params);
        })
        .on("/fail/:uuid", function (params) {
            render('fail', fail, params);
        })
        .notFound(() => {
            render('404');
        })
        .resolve();
});



